define('client/components/article-list/article-summary-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isOpen: false,
    showImpact: false,
    showTopFive: false,

    // Add a keydown event listener after the component is inserted into the DOM
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      document.addEventListener('keydown', this.handleKeydown.bind(this));
    },


    // Remove the keydown event listener when the component is destroyed
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      document.removeEventListener('keydown', this.handleKeydown.bind(this));
    },


    // Method to handle keydown event and close modal on Escape key
    handleKeydown: function handleKeydown(event) {
      if (event.key === 'Escape' && this.get('isOpen')) {
        this.send('cancel');
      }
    },


    top_5_articles_content: Ember.computed('top_5_articles', function () {
      var _this = this;

      debugger;
      return this.get("top_5_articles").filter(function (item) {
        return item !== null;
      }).map(function (ele, index) {
        return {
          url: ele[0] || "", // Default to empty string if null or undefined
          title: ele[1] || "", // Default to empty string if null or undefined
          isNotLast: !(index === _this.get("top_5_articles").length - 1)
        };
      });
    }),

    actions: {
      toggleImpact: function toggleImpact() {
        this.set('showImpact', !this.get('showImpact'));
      },
      toggleTopFive: function toggleTopFive() {
        this.set('showTopFive', !this.get('showTopFive'));
      },
      cancel: function cancel() {
        this.set('isOpen', false);
        this.aiSummaryDataReset(); // Assuming this resets data related to AI summary
      }
    }
  });
});